<template>
  <div class="container">
    <GeneralTable ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="operateTitle='添加', editData={},isAddVisible = true"
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="DistributionManagement"
      >
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList" v-loading="statisticsLoading" />
      </template>

      <template slot="operate-left">
        <el-button
          type="success"
          size="small"
          @click="handleBatchScheme"
        >批量操作</el-button>
      </template>
      <template slot="enable"
        slot-scope="{item}">
        <el-switch v-model="item.enable" :active-value="1" :inactive-value="0" disabled></el-switch>
      </template>
      <template slot="calculateCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline">{{ item.calculateCount }}</el-button>
      </template>
      <template slot="cardFileUrl"
        slot-scope="{item}">
        <el-image
            style="width: 32px; height: 32px;border-radius: 50%;"
            :src="item.cardFileUrl" :preview-src-list="[item.cardFileUrl]"
            fit="cover"></el-image>
      </template>
      <template slot="schemeCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline"
          @click="editData = item,isByUserVisible = true">{{ item.schemeCount }}</el-button>
      </template>
      <template slot="policyCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline">{{ item.policyCount }}</el-button>
      </template>
      <template slot="enquiryCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline"
         >{{ item.enquiryCount }}</el-button>
      </template>
      <template slot="customerCount"
        slot-scope="{item}">
        <el-button type="text"
          style="text-decoration:underline" v-if="!$store.state.userInfo.myAgentConcat"
          @click="handleAssociation(item)">{{ item.customerCount }}</el-button>
          <span v-else>{{ item.customerCount }}</span>
      </template>
      <template slot="operate"
        slot-scope="{item}">
        <el-button class="polecy-button"
          type="text"
          v-if="handleHasPerms('M31-list-edit')"
          size="small"
          @click="handleGoPath('/routerCalculateManagement/Record', item)">测算记录</el-button>
        <el-button class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M31-list-edit')"
          type="text"
          size="small">编辑</el-button>
        <el-button class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M31-list-del')"
          type="text"
          size="small">删除</el-button>
      </template>
    </GeneralTable>
    
    <editDialog v-model="isAddVisible"
      :auditReason="editData"
      @changeList="changeList" />
    <schemeDialog v-model="isByUserVisible" :formData="editData" />
    <!-- 关联用户弹框 -->
    <userDialog v-model="showTreeDialog" :formData="editData"></userDialog>
    <allocationSchemeDialog v-model="showAllocationSchemeDialog" :distribution="distributionList" />
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import editDialog from "@/views/DistributionManagement/components/editDialog.vue";
import schemeDialog from "@/views/DistributionManagement/components/schemeDialog.vue";
import userDialog from "@/views/DistributionManagement/components/userDialog.vue";
import allocationSchemeDialog from "@/views/DistributionManagement/components/allocationSchemeDialog.vue";
import { agentUserPage, agentUserPageOfDLR, agentUserDelete, getRelateTreeData, roleBindUser, listGrantedUser, exportExcelUser, agentStatistics } from "@/api/SystemData";
import { hasPerms } from "@/utils/auth";
export default {
  name: 'DistributionManagement',
  data() {
    return {
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: hasPerms('M31-list-del'), //删除 @del-change
        isEdit: hasPerms('M31-list-edit'), // 编辑 @edit-change
        isExport: hasPerms("M31-list-edit"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: '--',  // 列表数据为空时的显示
        searchList: [{
          label: '代理人名称',
          prop: 'realName',
          formType: 'input',
          maxLength: 11,
          clearable: true,
          placeholder: "请输入"
        }, {
          label: '代理人类型',
          prop: 'customerType',
          formType: 'select',
          filterable: true,
          placeholder: "请选择",
          selectList: [
            { value: '', text: '所有' },
            { value: 1, text: '个人' },
            { value: 2, text: '企业' },
          ]
        },
        {
          label: '联系方式',
          prop: 'concat',
          formType: 'input',
          maxLength: 11,
          clearable: true,
          placeholder: "请输入"
        }, {
          label: '注册时间',
          formType: 'daterange',
          clearable: true,
            format: 'yyyy.MM.dd',
          prop: ['createTimeBegin', 'createTimeEnd']
        }, 
        {
          label: '证件号码',
          prop: 'cardNo',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入"
        }, {
          label: '层级',
          prop: 'rank',
          formType: 'select',
          filterable: true,
          placeholder: "请选择类型",
          selectList: [
            { value: '', text: '全部' },
            { value: 1, text: '一级' },
            { value: 2, text: '二级' },
          ],
          isPermissions: !this.$store.state.userInfo.myAgentConcat
        },
        {
          label: '上级',
          prop: 'superiorName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入",
          isPermissions: !this.$store.state.userInfo.myAgentConcat
        },{
          label: '状态',
          prop: 'enable',
          formType: 'select',
          filterable: true,
          placeholder: "请选择类型",
          selectList: [
            { value: '', text: '全部' },
            { value: '1', text: '启用' },
            { value: '0', text: '禁用' },
          ]
        }, 
        {
          label: '业务员',
          prop: 'salesmanName',
          formType: 'input',
          maxLength: 32,
          clearable: true,
          placeholder: "请输入"
        },
        {
          label: '代理人编号',
          prop: 'code',
          formType: 'input',
          maxLength: 23,
          clearable: true,
          placeholder: "请输入"
        }]
      },
      column: [
        {
          label: '序号',
          type: 'index',
          isShow: true,
          fixed: 'left'
        },
        {
          label: "代理人编号",
          prop: "code",
          align: 'center',
          isShow: true,
          width: 180
        },
        {
          label: "姓名",
          prop: "realName",
          align: 'center',
          isShow: true,
        },
        {
          label: "联系方式",
          prop: "concat",
          align: 'center',
          isShow: true,
          width: 140
        },
        {
          label: "类型",
          prop: "customerTypeDesc",
          align: 'center',
          isShow: true,
        },
        {
          label: "企业名称",
          prop: "companyName",
          align: 'center',
          isShow: true,
          width: 140
        },
        {
          label: "证件号码",
          prop: "cardNo",
          align: 'center',
          isShow: true,
          width: 180
        },
        {
          label: "证件照片",
          prop: "cardFileUrl",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "层级",
          prop: "rankDesc",
          align: 'center',
          isShow: true,
          isPermissions: !this.$store.state.userInfo.myAgentConcat
        },
        {
          label: "上级",
          prop: "superiorName",
          align: 'center',
          isShow: true,
          isPermissions: !this.$store.state.userInfo.myAgentConcat
        },
        {
          label: "客户数量",
          prop: "customerCount",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "测算单数量",
          prop: "calculateCount",
          align: 'center',
          width: '100',
          isShow: true,
          // isSlot: true,
        },
        {
          label: "询价单数量",
          prop: "enquiryCount",
          align: 'center',
          width: '100',
          isShow: true,
          // isSlot: true,
        },
        {
          label: "保单数量",
          prop: "policyCount",
          align: 'center',
          width: '100',
          isShow: true,
          // isSlot: true,
        },
        {
          label: "可见方案",
          prop: "schemeCount",
          align: 'center',
          width: '100',
          isShow: true,
          isSlot: true,
        },
        {
          label: "签单保费",
          prop: "premiumSum",
          align: 'center',
          isShow: true,
        },
        {
          label: "所属业务员",
          prop: "salesmanName",
          align: 'center',
          isShow: true,
          width: 120
        },
        {
          label: "状态",
          prop: "enable",
          align: 'center',
          isShow: true,
          isSlot: true,
        },
        {
          label: "备注",
          prop: "remark",
          align: 'center',
          isShow: true,
        },
        {
          label: "注册时间",
          prop: "createTime",
          align: 'center',
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: 'center',
          width: 300,
          fixed: 'right',
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: '添加',
      isAddVisible: false,
      editData: {},
      isByUserVisible: false,
      roleId: '',
      showTreeDialog: false,
      showAllocationSchemeDialog: false,
      distributionList: [],
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      topPickerData: [],
      oldInterfaceData: {},
      schemeFormData: [],
      statisticsList: [
        {
          name: '代理人总数',
          key: 'agentTotal',
          value: 0
        },
        {
          name: '一级代理人总数',
          key: 'firstAgentCount',
          value: 0
        },
        {
          name: '二级代理人总数',
          key: 'secondAgentCount',
          value: 0
        },
        {
          name: '个人代理人总数',
          key: 'grAgentCount',
          value: 0
        },
        {
          name: '企业代理人总数',
          key: 'qyAgentCount',
          value: 0
        },
        {
          name: '客户总数',
          key: 'customerCount',
          value: 0
        },{
          name: '签单总保费',
          key: 'premiumSum',
          value: 0
        }
      ],
      statisticsLoading: false
    };
  },
  components: { GeneralTable, editDialog, schemeDialog, userDialog, allocationSchemeDialog, StatisticsBox },
  created() {
      if (this.$store.state.userInfo.myAgentConcat && this.$store.state.userInfo.myAgentRank == 1) {
        this.statisticsList = [
          {
            name: '代理人总数',
            key: 'agentTotal',
            value: 0
          },
          {
            name: '客户总数',
            key: 'customerCount',
            value: 0
          },{
            name: '签单总保费',
            key: 'premiumSum',
            value: 0
          }
        ]
      }else if (this.$store.state.userInfo.myAgentConcat) {
        this.statisticsList = [
          {
            name: '客户总数',
            key: 'customerCount',
            value: 0
          },{
            name: '签单总保费',
            key: 'premiumSum',
            value: 0
          }
        ]
      }
  },
  watch: {
  },
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize) {
      this.oldInterfaceData = { ...v, current: pageNum, size: pageSize, fastLike: v.keyword, createTimeBegin: v.createTimeBegin ? v.createTimeBegin + ' 00:00:00' : '', createTimeEnd: v.createTimeEnd ? v.createTimeEnd + ' 23:59:59' : '' }
      agentStatistics(this.oldInterfaceData).then(res=>{
        this.statisticsList.map((statsItem,statsIndex)=>{
          this.statisticsList[statsIndex].value = res.data[statsItem.key];
        })
      })
      let code = 0;
      let dataObj = {};
      if (this.$store.state.userInfo.myAgentConcat) {
        const data = await agentUserPageOfDLR(this.oldInterfaceData);
        code = data.code;
        dataObj = data.data;
      }else {
        const data = await agentUserPage(this.oldInterfaceData);
        code = data.code;
        dataObj = data.data;
      }
      if (code === 200) {
        this.claimRecordList = dataObj.records;
        this.totalNum = dataObj.total;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        // const flag = e.findIndex(val => val.grantAccountCount > 0);
        // if (flag > -1) {
        //   this.$message({
        //     type: 'warning',
        //     message: '请先移除该代理人!'
        //   });
        //   return false;
        // }
        this.$confirm('确认删除该代理人?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = e.map(val => val.id).toString();
          agentUserDelete({
            ids
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.changeList();
          }).catch(err => {
            this.$message({
              type: 'error',
              message: '失败!'
            });
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的代理人！');
      }
    },
    // 编辑
    editItem(e) {
      this.editData = e;
      this.isAddVisible = true;
    },
    handleBatchScheme() {
      if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
        this.distributionList = this.$refs.GeneralTable.tableSelectionList.map(val => val.userId);
        this.showAllocationSchemeDialog = true;
      } else {
        this.$message.warning('请选择代理人！');
        return false;
      }
    },
    // 跳页面
    handleGoPath(path, item) {
      // let ids = '';
      // let name = '';
      // let tenantId = '';
      // if (item) {
      //   ids = item.id;
      //   name = item.roleName;
      //   tenantId = item.tenantId;
      // } else if (this.$refs.GeneralTable.tableSelectionList && this.$refs.GeneralTable.tableSelectionList.length) {
      //   ids = this.$refs.GeneralTable.tableSelectionList.map(val => val.id).toString();
      //   name = this.$refs.GeneralTable.tableSelectionList.map(val => val.roleName).toString();
      // } else {
      //   this.$message.warning('请选择需要设置的角色！');
      //   return false;
      // }
      this.$router.push({
        path: `${ path }?agentUserId=${ item.userId }`,
      });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的角色！');
      //   return false;
      // }
      exportExcelUser({
         ...this.oldInterfaceData,
        roleIds: e.map(val => val.id).toString()
      });
    },
    async handleAssociation(item) {
      this.editData = item;
      this.showTreeDialog = true;
    },
    submitRelateUser(e) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      roleBindUser({
        roleId: this.editData.id,
        userIds: e.map(val => val.id)
      }).then(res => {
        this.changeList();
        loading.close();
      }).catch(err => {
        loading.close();
        console.log(err);
      });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    }

  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>