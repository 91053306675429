<template>
  <el-dialog
    title="批量操作"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="85%"
    :before-close="handleClose"
  >
    <div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding-left: 12px;
        "
      >
        <el-select
          v-model="operatorType"
          placeholder="请选择"
          style="max-width: 300px"
        >
          <el-option
            label="添加"
            :value="1"
          >
          </el-option>
          <el-option
            label="删除"
            :value="2"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入内容"
            style="max-width: 300px;"
            v-model="keyword"
            clearable>
            <el-button slot="append"
              icon="el-icon-search"
              @click="queryData"></el-button>
          </el-input>
      </div>
      <el-table
        :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="100"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="insureProgrammeName" label="产品方案名称">
          <template slot-scope="{ row }">
            {{ row.insureProgrammeName }}
          </template>
        </el-table-column>
        <el-table-column
          prop="vehicleTypeName"
          label="设备类型"
        ></el-table-column>
        <el-table-column
          prop="clientDisplayName"
          label="险种类型"
        ></el-table-column>
        <el-table-column prop="categoryName" label="所属部门"></el-table-column>
        <el-table-column prop="companyName" label="保险公司"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { insureProgrammeByAgentPage } from "@/api/policy";
import { batchBindProgramme } from "@/api/SystemData";
export default {
  props: {
    showDialog: Boolean,
    distribution: {
      type: Array,
      default: () => [],
    },
    totalPremium: {
      type: [Number, String],
      default: 0,
    },
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            insureProgrammeName: "",
          };
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        insureProgrammeName: "",
      },
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {},
      indemnityNum: 0,
      indemnityRate: 0,
      keyword: "",
      operatorType: 1,
    };
  },
  methods: {
    getTableData(e) {
      insureProgrammeByAgentPage(
        { ...this.formInline, current: this.current, size: this.size }
      )
        .then((res) => {
          if (res.data && res.data.records && res.data.records.length) {
            this.tableData = res.data.records.map((record) => {
              record.programmeId = record.id;
              return record;
            });
            this.total = res.data.total;
          } else {
            this.tableData = [];
          }
        })
        .catch((err) => {
          this.tableData = [];
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    onReset() {
      this.current = 1;
      this.formInline = {
        insureProgrammeName: "",
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData("current");
    },
    handleSizeChange(e) {
      this.size = e;
      this.getTableData("size");
    },
    handleSelectionChange(e) {
      this.fileList = e;
    },
    handleGoPath(e) {
      console.log(e);
      this.$router.push({
        path:
          "/routerPolicyManagement/ConstructionMachinery/policyDetails/" +
          e.policyNo,
      });
    },
    queryData() {
      this.formInline.insureProgrammeName = this.keyword;
      this.current = 1;
      this.getTableData();
    },
    handleSubmit() {
      if (!this.fileList || this.fileList.length == 0) {
        this.$message({
          message: "请选择要操作的方案",
          type: "error",
        });
        return false;
      }
      batchBindProgramme({
        agentUserIds: this.distribution,
        operatorType: this.operatorType,
        programmeList: this.fileList,
      }).then((res) => {
        console.log(res);
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
}
</style>