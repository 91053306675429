<template>
  <el-dialog
    title="编辑"
    :visible.sync="dialogVisible"
    custom-class="policy-import-dialog"
    width="50%"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        label="名称："
        prop="realName"
      >
        <el-input v-model="ruleForm.realName" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="联系方式："
        prop="concat"
      >
        <el-input v-model="ruleForm.concat" disabled></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="cardType">
        <el-radio-group v-model="ruleForm.cardType" disabled>
          <el-radio :label="1">个人</el-radio>
          <el-radio :label="2">企业</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="企业名称：" v-if="ruleForm.cardType == 2"
        prop="companyName"
      >
        <el-input v-model="ruleForm.companyName" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="证件号码："
        prop="cardNo"
      >
        <el-input v-model="ruleForm.cardNo" disabled></el-input>
      </el-form-item>
      <el-form-item
        label="证件照片："
        prop="cardFileUrl"
      >
        <el-image
            style="width: 32px; height: 32px;border-radius: 50%;"
            :src="ruleForm.cardFileUrl" :preview-src-list="[ruleForm.cardFileUrl]"
            fit="cover"></el-image>
      </el-form-item>
      <el-form-item label="绑定业务员" prop="salesmanId">
        <el-select
          v-model="ruleForm.salesmanName"
          filterable disabled
          remote @change="handleChangeReferrer"
          placeholder="请选择"
        >
          <el-option
            :label="item.salesmanName"
            :value="item.salesmanName"
            v-for="(item,index) in referrerList"
            :key="index"
          ></el-option>
        </el-select>
      <el-button type="text" style="margin-left: 12px" @click="isAllocationCustomer = true" v-if="!$store.state.userInfo.myAgentConcat">更换</el-button>
      </el-form-item>
      <el-form-item label="启用状态" prop="enable">
        <el-radio-group v-model="ruleForm.enable">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <!-- <div>备注</div> class="label-none" -->
        <el-input type="textarea" v-model="ruleForm.remark" :disabled="isDisabled" rows="6"></el-input>
      </el-form-item>
      <el-form-item label="可见方案" prop="fa" class="label-none">
        <div><span style="color: red">*</span>可见方案</div>
        <schemeBox
          :treeData="relateTree"
          ref="schemeBox"
          :treeSelectKeyList="treeSelectKeyList"
          :defaultProps="treeProps"
          :showOldSelectNode="true"
          :topPickerData="topPickerData"
          v-loading="isLoading"
          :isShowOnly="isDisabled"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleImport">确 定</el-button>
    </span>
      <recommendDialog :recommendId="$store.state.tenantId" title="选择业务员" @changeID="handleSetRuleForm" v-model="isAllocationCustomer" />
  </el-dialog>
</template>

<script>
import schemeBox from "@/views/DistributionManagement/components/schemeBox.vue";
import { insureProgrammeByAgent } from "@/api/policy";
import { agentUserUpdate, agentUserDetail, getOwnBusiness } from "@/api/SystemData";
import recommendDialog from "@/views/SystemManagement/components/customer/recommendDialog.vue";

export default {
  props: {
    showDialog: Boolean,
    auditReason: {
      type: Object,
      default: () => {},
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  components: { schemeBox, recommendDialog },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    'ruleForm.processStatus': {
      handler(v) {
        if (v == 1) {
          this.ruleForm.processStatusDesc = "已通过";
        }else {
          this.ruleForm.processStatusDesc = "已退回";
        }
      }
    },
    showDialog: {
      handler(v) {
        this.$refs.schemeBox ? this.$refs.schemeBox.selectList = [] : '';
        this.treeSelectKeyList = [];
        if (v) {
          agentUserDetail(this.auditReason).then(res=>{
            this.ruleForm = res.data;
            this.ruleForm.userId = this.auditReason.userId;
            this.treeSelectKeyList = res.data.programmeList;
            if (this.ruleForm.cardNo) {
              getOwnBusiness({
                cardNo: this.ruleForm.cardNo
              }).then(res2=>{
                this.referrerList = res2.data;
              })
            }
          });
        }else {
          this.ruleForm = {
            remark: "",
            processStatus: 1,
            processStatusDesc: '已通过',
            programmeList: [],
          };
        }
        this.dialogVisible = v;
        if (v && this.relateTree.length == 0 && !this.isDisabled) {
          this.isLoading = true;
          insureProgrammeByAgent()
            .then((res) => {
              this.isLoading = false;
              this.relateTree = res.data.map((val) => {
                val.insureProgrammeName = val.companyName;
                val.insureProgrammeRespList ? val.insureProgrammeRespList = val.insureProgrammeRespList.map(item=>{
                  item.programmeId = item.id;
                  return item
                }) : '';
                return val;
              });
            })
            .catch((err) => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      ruleForm: {
        remark: "",
        processStatus: 1,
        processStatusDesc: '已通过',
        programmeList: [],
      },
      rules: {
        salesmanId: [
          { required: true, message: "请选择业务员", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        concat: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        cardNo: [
          { required: true, message: "请输入", trigger: "change" },
        ],
        cardFileUrl: [
          { required: true, message: "请选择", trigger: "change" },
        ],
      },
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "insureProgrammeRespList",
        label: "insureProgrammeName",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "companyName",
        uuidName: "programmeId",
      },
      topPickerData: [],
      isLoading: false,
      referrerList: [],
      isAllocationCustomer: false,
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handleImport() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.ruleForm.id = this.auditReason.id;
          this.ruleForm.programmeList = this.$refs.schemeBox
            .submitData()
            // .map((val) => {
            //   val.programmeId = val.id;
            //   return val;
            // });
          if (
            !this.ruleForm.programmeList ||
            this.ruleForm.programmeList.length == 0
          ) {
            this.$message.error("请选择可见方案");
            return false;
          }
          agentUserUpdate(this.ruleForm).then((res) => {
            this.dialogVisible = false;
            this.$emit("changeList");
          });
        } else {
          console.log("error submit!!");
          this.$message.error("保存失败！");
          return false;
        }
      });
    },
   handleChangeReferrer(e) {
      let data = this.referrerList.find(val=> val.salesmanName == e)
      this.ruleForm.salesmanId = data.uniqueId;
   },
   handleSetRuleForm(e) {
      this.isAllocationCustomer = false;
      // e.referrerName = e.referrerUserName;
      Object.assign(this.ruleForm, { salesmanId: e.referrerUserId, salesmanName: e.referrerUserName});
   },
  },
};
</script>

<style lang="scss">
.policy-import-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
}
</style>
<style lang="scss">
.label-none {
  .el-form-item__label {
    display: none;
  }
  .el-form-item__content {
    margin: 0 !important;
    padding-left: 32px;
  }
}
</style>