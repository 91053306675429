<template>
  <el-dialog title="客户列表"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="85%"
    :before-close="handleClose">
    <div>
      <div style="display: flex;justify-content: space-between;padding-left:12px">
        <el-button type="primary" icon="el-icon-refresh" size="small" plain @click="getTableData"></el-button>
        <el-input placeholder="请输入内容"
            style="max-width: 300px;"
            v-model="keyword"
            clearable>
            <el-button slot="append"
              icon="el-icon-search"
              @click="queryData"></el-button>
          </el-input>
      </div>
      <el-table :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <!-- <el-table-column type="selection"
          width="100"></el-table-column> -->
        <el-table-column type="index"
          label="序号"
          width="50"></el-table-column>
        <el-table-column prop="userName"
          label="用户昵称"></el-table-column>
        <el-table-column prop="phoneNum"
          label="手机号"></el-table-column>
        <el-table-column prop="referrerName"
          label="推荐人"></el-table-column>
        <el-table-column prop="customerTypeDesc"
          label="用户类型"></el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"></el-pagination>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
        @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { agentUserPageCustomer } from "@/api/SystemData";
export default {
  props: {
    showDialog: Boolean,
    formData: {
      type: Object,
      default: () => { }
    },
    totalPremium: {
      type: [Number, String],
      default: 0
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.formInline = {
            fastLike: ''
          };
          this.getTableData();
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      formInline: {
        fastLike: ''
      },
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
      oldFormInline: {},
      indemnityNum: 0,
      indemnityRate: 0,
      keyword: ''
    };
  },
  methods: {
    getTableData(e) {
      agentUserPageCustomer({agentUserId: this.formData.userId, current: this.current, size: this.size, ...this.formInline}).then(res => {
        if (res.data && res.data.records && res.data.records.length) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
        }
      }).catch(err => {
        this.tableData = [];
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    onReset() {
      this.current = 1;
      this.formInline = {
        fastLike: ''
      };
      this.getTableData();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getTableData('current');
    },
    handleSizeChange(e) {
      this.size = e;
      this.getTableData('size');
    },
    handleSelectionChange(e) {
      console.log(e);
      if (e.length) {
        this.fileList = [e[e.length - 1]];
      }
      if (e.length > 1) {
        // this.$message.error('只能选择一个保单！');
        const id = e[e.length - 1].id;
        const index = this.tableData.findIndex(val => val.id == id);
        this.$refs.multipleTable.clearSelection();
        this.$refs.multipleTable.toggleRowSelection(this.tableData[index], true);
      }
    },
    handleGoPath(e) {
      console.log(e);
      this.$router.push({ path: '/routerPolicyManagement/ConstructionMachinery/policyDetails/' + e.policyNo });
    },
    queryData() {
      this.formInline.fastLike = this.keyword;
      this.current = 1;
      this.getTableData();
    }
  }
};
</script>

<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
}
</style>